import React from "react"

import Banner from "../components/banner/banner"
import bg from "../images/bg.png"

import "./404.scss"

var notexist = props => (
  <>
    <div className="404-page">
      <Banner
        src={bg}
        title="Oops :("
        subTitle="Sorry, this page doesn't exist. Click above to navigate to a page that does..."
        height="27.5rem"
      />
    </div>
  </>
)

export default notexist
